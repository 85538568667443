<template>
<div>
  <div class="infoContainer">
    <h2>Jak to działa?</h2>
    <p>Zabawa polega na znajdywaniu kodów, które są rozłożone po całym terenie konwentu. Pomagać w tym będą podpowiedzi, które widzisz na stronie głównej.</p>
    <p>Po zebraniu kodu masz 15 minut, aby dotrzeć i zeskanować następny. Po zebraniu trzech kodów możesz zalosować po nagrodę.</p>
  </div>
  <div class="infoContainer">
    <h2>Jakie są nagrody?</h2>
    <p>Są trzy kategorie:</p>
    <ul>
      <li>3⭐ - małe gadżety konwentowe, naklejki i przypinki.</li>
      <li>4⭐ - tomy mang, a dla chętnych - welkiny z Genshina.</li>
      <li>5⭐ - główna nagroda - <b>dakimakura z twoją waifu!</b></li>
    </ul>
  </div>
  <div class="infoContainer">
    <h2>Jak można odebrać nagrody?</h2>
    <p>Rozdanie nagród odbędzie się:</p>
  </div>
  <Chip v-if="claimInfo.date != undefined">
    {{ parseDate(claimInfo.date) }} {{ claimInfo.location }}
    {{ claimInfoError }}
  </Chip>
  <div class="infoContainer">
    <p>Jeśli będzie potrzebne wcześniejsze odebranie nagrody, zapraszamy do kontaku.</p>
  </div>

</div>
</template>

<script>
import Chip from 'primevue/chip';
import apiCalls from '@/services/apiCalls.service.js';

export default {
  data() {
    return {
      claimInfo: {},
      claimInfoError: ""
    }
  },
  created(){
    this.fetchClaimInfo()
  },
  methods: {
    fetchClaimInfo(){
      apiCalls.get("claim-info/")
        .then(res => this.claimInfo = res)
        .catch(err => {
          console.log(err)
          this.claimInfoError = "Coś poszło nie tak, spróbuj ponownie później."
        })
    },
    parseDate(dateString){
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('pl-PL', { weekday: "long", hour: "numeric", minute: "numeric" }).format(date);
    }
  },
  components: {
    Chip
  }
}
</script>

<style lang="scss" scoped>
.infoContainer {
  text-align: left;
  padding: 1rem;
}
.infoContainer p {
  margin-left: 1rem;
}
</style>